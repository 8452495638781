import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Stack, Typography, Link } from "@mui/material";
import { currentFormat } from "utils/convert";
import {
  shipalLogo5,
  coordinadoraLogo,
  dhlLogo,
  fedexLogo,
} from "assets/imgs";
import LinkAction from "components/molecules/Link-action";

type Props = {
  order: {
    _id: string;
    consignee: {
      country_code: string;
      city: string;
      state: string;
      postal_code: string;
    };
    client_name: string;
    due_diligence: {
      carrier_selected: boolean;
    };
    total_payment: number;
    shipal: {
      selected_carrier: string;
    };
    is_international_shippment: boolean;
  };
  can_modify?: boolean;
};

export const CarrierInfoTableCell = ({ order, can_modify }: Props) => {
  const history = useHistory();

  const carrierPlaceHolderStyle: React.CSSProperties = useMemo(
    () => ({
      maxWidth: "50px",
      width: "100%",
    }),
    []
  );

  const handleSelectedOrder = useCallback(
    (isNational: boolean, id: string) =>
      history.push(
        `/shipping/${isNational ? "international" : "national"}/${id}`
      ),
    [history]
  );

  const linkStyle: React.CSSProperties = useMemo(
    () => ({
      fontWeight: "bold",
      cursor: "pointer",
    }),
    []
  );

  const blueColorStyle: React.CSSProperties = useMemo(
    () => ({
      ...linkStyle,
      color: "#1890FF",
    }),
    [linkStyle]
  );

  const getCarrierLogo = (carrierLogo: string) => {
    let selectedCarrierLogo: string;

    switch (carrierLogo) {
      case "COORDINADORA":
        selectedCarrierLogo = coordinadoraLogo;
        break;
      case "DHL":
        selectedCarrierLogo = dhlLogo;
        break;
      case "FEDEX":
        selectedCarrierLogo = fedexLogo;
        break;
      default:
        selectedCarrierLogo = shipalLogo5;
        break;
    }

    return (
      <img src={selectedCarrierLogo} style={carrierPlaceHolderStyle} alt="" />
    );
  };

  const getminHeight = {
    top: can_modify ? "30%" : "70%",
    bot: can_modify ? "70%" : "30%",
  };

  return order.due_diligence.carrier_selected &&
    Boolean(order.total_payment) ? (
    <Stack direction="row" alignItems="center" width={"100%"} height={"100%"}>
      <Stack
        sx={{
          width: 56,
        }}
      >
        {getCarrierLogo(order.shipal.selected_carrier)}
      </Stack>

      <Stack
        direction="column"
        textAlign="left"
        sx={{
          width: 80,
        }}
        height={"100%"}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ minHeight: getminHeight.top }}
        >
          <Typography variant="caption" fontWeight="bold">
            {order.shipal.selected_carrier.toUpperCase()}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="center"
          sx={{ minHeight: getminHeight.bot }}
        >
          <Typography variant="caption">
            {currentFormat(order.total_payment)}
          </Typography>

          {can_modify && (
            <Link
              sx={blueColorStyle}
              onClick={() =>
                handleSelectedOrder(order.is_international_shippment, order._id)
              }
            >
              Cambiar
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <LinkAction
      text="CONFIGURAR ENVÍO"
      action={() =>
        handleSelectedOrder(order.is_international_shippment, order._id)
      }
    />
  );
};
