import * as yup from "yup";

export const declareExportInternationalSchema = yup.object({
  contents: yup
      .string()
      .required("Ingrese un propósito de envío")
      .max(70, "El propósito de envío no puede exceder los 70 caracteres"),
  export_reason: yup.string().required("Ingrese una razon de exportacion"),
  declared_value: yup.number().min(1,"Ingrese el valor declarado").required()
});
