import {
  Box,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { EmptyButtonSenders } from "commons/empty-button-senders";
import { fullFormProp } from "components/molecules/order-forms/types";
import React, { useState } from "react";
import { InternationalDeclareExportForm } from "./form";
import { DeclareExportCard } from "./card";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { TITLE_INTERNATIONAL } from "../constants";
import { exportDeclaration } from "assets/icons";

type DeclareExportProps = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
  isForQuote?: boolean;
  setSearch?: React.Dispatch<any>;
};

export const DeclareExportInternational = ({
  data,
  fnSave,
  isForQuote,
  setSearch,
}: DeclareExportProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleModal = () => {
    setIsOpenModal((state) => !state);
  };

  return (
    <>
      {data.export_declaration?.export_reason ? (
        <DeclareExportCard
          body={data.export_declaration!}
          contents={data.contents!}
          title={TITLE_INTERNATIONAL}
          handleModal={handleModal}
          declared_value={data.declared_value!}
        />
      ) : isForQuote ? (
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: 32,
                mr: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <img
                src={exportDeclaration}
                alt="icono de campos pendientes por llenar"
              />
            </Box>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <Typography variant="h4" sx={{ textAlign: "left" }}>
                  {" "}
                  {TITLE_INTERNATIONAL}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px!important",
                    color: "#677787",
                    textAlign: "left",
                  }}
                >
                  Ingresa la información aduanera de tu envío
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "15rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  width: "100%",
                }}
              >
                <InternationalDeclareExportForm
                  data={data}
                  fnSave={fnSave}
                  isForQuote={isForQuote}
                  handleModal={() => {}}
                  setSearch={setSearch}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      ) : (
        <EmptyButtonSenders
          title={TITLE_INTERNATIONAL}
          titleButton="Configurar"
          handleModal={handleModal}
          warningInput="Ingresa la información aduanera de tu envío"
          icon={exportDeclaration}
        />
      )}
      <Dialog open={isOpenModal} onClose={handleModal}>
        <DialogTitle>Declaración de Exportación</DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Card>
            <CardContent title={TITLE_INTERNATIONAL}>
              <InternationalDeclareExportForm
                data={data}
                fnSave={fnSave}
                handleModal={handleModal}
                setSearch={setSearch}
              />
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};
