import { Dialog, DialogContent } from "@mui/material";
import { EmptyButtonSenders } from "commons/empty-button-senders";
import { EmptyQuoteAddressConfigCard } from "commons/empty-quote-address-config-card";
import {
  ConsigneeProps,
  ShipperProps,
  fullFormProp,
} from "components/molecules/order-forms/types";
import React, { createElement, useCallback, useMemo, useState, useEffect } from "react";
import { ContactCard } from "../contactCard";
import { onSubmitFormRecipmentAndSender } from "./utils";
import { recipientIcon, senderIcon } from "assets/icons";
import { ModalTitle } from "../ModalTitle";
import { AddressForm } from 'components/molecules/order-forms/addressForm';
import { SHIPPING_TYPE } from "enums/shipping";

type Props = {
  title: string;
  data?: Partial<fullFormProp>;
  isShipper: boolean;
  fnSave: (
    data: any,
    attribute: string,
    values: ShipperProps | ConsigneeProps
  ) => Promise<any>;
  isForQuote?: boolean;
  typeShipping?: SHIPPING_TYPE;
};

export const AddressInfoCard = ({
  data,
  title,
  isShipper,
  fnSave,
  isForQuote,
  typeShipping
}: Props) => {
  const data_reference: ShipperProps | ConsigneeProps = isShipper
    ? data?.shipper!
    : data?.consignee!;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [typeShippingState, setTypeShippingState] = useState<SHIPPING_TYPE>(
    typeShipping!
  );
  const handleModal = useCallback(() => setIsOpenModal((state) => !state), []);

  const onSubmit = async (
    values: ShipperProps | ConsigneeProps,
    isValid: boolean = true
  ) => {
    if (!isValid) return;
    console.log("pase", isValid);
    await onSubmitFormRecipmentAndSender({
      data,
      isShipper,
      fnSave,
      values,
    });
    setIsOpenModal(false);
  };
  useEffect(() => {
    setTypeShippingState(() => typeShipping!);
  }, [typeShippingState, typeShipping, setTypeShippingState]);
  const existInformation =
    (isShipper && data?.shipper?.address_line) ||
    (!isShipper && data?.consignee?.address_line);

  const loadCardOrEmptyButton = useMemo(() => {
    if (existInformation) {
      return (
        <ContactCard
          body={data_reference}
          title={title}
          handleModal={handleModal}
        />
      );
    }
    if (isForQuote) {
      return (
        <EmptyQuoteAddressConfigCard
          title={title}
          handleChange={fnSave}
          warningInput={
            isShipper
              ? "Ingresa la direccion de origen"
              : "Ingresa la direccion de destino"
          }
          icon={senderIcon}
          typeShipping={typeShippingState!}
          isShipper={isShipper!}
          currentFormData={data!}
          initialValues={isShipper ? data?.shipper! : data?.consignee!}
        />
      );
    }

    return (
      <EmptyButtonSenders
        title={title}
        isforQuote={isForQuote}
        titleButton={"Configurar"}
        handleModal={handleModal}
        warningInput={
          isShipper
            ? "Ingresa la direccion de origen"
            : "Ingresa la direccion de destino"
        }
        icon={isShipper ? senderIcon : recipientIcon}
      />
    );
  }, [data_reference, typeShippingState, data]);

  return (
    <>
      {loadCardOrEmptyButton}

      <Dialog open={isOpenModal} onClose={handleModal}>
        <ModalTitle title={title} />
        <DialogContent
          sx={{
            maxWidth: "500px",
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <AddressForm
            fnSave={onSubmit}
            formData={data}
            initialValues={data_reference}
            isShipper={isShipper}
            isForQuote={isForQuote}
            handleClose={handleModal}
            typeShipping={typeShipping}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
