import * as Yup from "yup";
import { REQUIRED_COUNTRIES } from "../const";

const specialCharsRegex = /^[A-Za-z0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
const message = 'Evita el uso de los caracteres "&", "Ñ" y las tildes.';

const especialCharsWitnÑ = /^[A-Za-zÑñ0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_{|}~]*$/;
const messageWithÑ = 'Evita el uso de los caracteres "&", y las tildes.';

const poBoxMessage = 'No se permite el uso de casilleros "PO BOX" en las direcciónes.';
const poBoxRegex = /\b(P\s*O|PO\s*Box)\b/i;

const addressMessage = "La dirección debe tener como máximo 35 caracteres"

export const shipperAddressSchema = Yup.object({
  address_line: Yup.string()
    .trim()
    .required("Debes agregar una dirección")
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  address_line2: Yup
    .string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  address_line3: Yup
    .string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});

export const consigneeAddressSchema = Yup.object({
  taxes_id: Yup.string().when("country_name", {
    is: (country_name: string) =>
      REQUIRED_COUNTRIES.includes(country_name),
    then: Yup.string()
      .trim()
      .required("Este campo es obligatorio para México, Perú y Argentina")
      .matches(specialCharsRegex, message),
    otherwise: Yup.string().trim().optional(),
  }),
  tax_identification: Yup.string().when("country_name", {
    is: "Brasil",
    then: Yup.string()
      .trim()
      .required(
        "El número de identificación fiscal es obligatorio para Brasil"
      ),
    otherwise: Yup.string().trim().optional(),
  }),
  tax_identification_type: Yup.string().when("country_name", {
    is: "Brasil",
    then: Yup.string()
      .trim()
      .required("Debe seleccionar CNPJ o CPF para Brasil"),
    otherwise: Yup.string().trim().optional(),
  }),
  ie_rg: Yup.string().trim().optional(),
  address_line: Yup.string()
    .trim()
    .required("Debes agregar una dirección")
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  address_line2: Yup.string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  address_line3: Yup.string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message)
    .max(35, addressMessage)
    .test("no-po-box", poBoxMessage, value => !poBoxRegex.test(value || "")),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});
