import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import type { TypographyProps, BoxProps } from "@mui/material";
import { pendingCourier } from "assets/icons";
import { SHIPPING_TYPE } from "enums/shipping";
import { LoadingButton } from "@mui/lab";
import { SearchQuote } from "components/molecules/order-forms/types";

type SkeletonProps = {
  height: string;
  error?: boolean;
  typeShipping: SHIPPING_TYPE;
  isforQuote?: boolean;
  setSearch?: React.Dispatch<React.SetStateAction<SearchQuote>>;
  isLoading?: boolean;
  search?: SearchQuote;
};

export const SkeletonInformation = ({
  height,
  error,
  isforQuote,
  typeShipping,
  isLoading,
  setSearch,
  search,
}: SkeletonProps) => {
  const textStyles = useMemo<TypographyProps["sx"]>(
    () => ({
      fontFamily: "Roboto-Regular",
    }),
    []
  );

  const boxErrorStyle = useMemo<BoxProps["sx"]>(
    () => ({
      width: "300px",
      mr: "auto",
      ml: "auto",
      minHeight: "100px",
      mt: "auto",
      mb: "auto",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 2,
    }),
    []
  );

  const boxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "1rem",
      height: { height },
      backgroundColor: "#f4f6f8",
      textAlign: "center",
      pl: 4,
      pr: 4,
    }),
    [height]
  );

  const onSave = async (): Promise<void> => {
    if (setSearch) setSearch((prev) => ({ ...prev, search: true }));
  };

  return (
    <Box sx={boxStyles}>
      {error && !search?.newValue? (
        <Typography sx={textStyles}>
          No tenemos proveedores para este envío
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" sx={boxErrorStyle}>
          <img
            height="43px"
            src={pendingCourier}
            alt="campos pendientes por llenar"
          />
          {typeShipping === SHIPPING_TYPE.NATIONAL && (
            <Typography sx={textStyles}>
              Para obtener una cotización, debe completar los campos. (Origen,
              Destino, y Detalle del paquete)
            </Typography>
          )}
          {typeShipping === SHIPPING_TYPE.INTERNATIONAL && (
            <Typography sx={textStyles}>
              Para obtener una cotización, debe completar los campos. (Origen,
              Destino, Detalle del paquete, y Declaración de exportación)
            </Typography>
          )}
          {isforQuote && (
            <LoadingButton
              onClick={onSave}
              loading={isLoading}
              type="button"
              variant="contained"
            >
              Cotizar
            </LoadingButton>
          )}
        </Box>
      )}
    </Box>
  );
};
