import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Dashboard } from "components/templates/page-private-dashboard";
import { SHIPPING_TYPE } from "enums/shipping";
import React, { createElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getOrderById, putOrder, getConfig } from "services/serviceV2/orders";
import { HorizontalBar } from "components/molecules/order-components/horizontalBar";
import { DraftFormInternational } from "components/molecules/order-forms/international";
import { DraftFormNational } from "components/molecules/order-forms/national";
import {
  ConsigneeProps,
  Due_diligenceProps,
  Export_declarationProps,
  Package_informationProps,
  ShipalProps,
  ShipperProps,
  fullFormProp,
} from "components/molecules/order-forms/types";
import {
  EDIT_PATH_INTERNATIONAL,
  EDIT_PATH_NATIONAL,
} from "components/molecules/order-forms/breadcrumbs/edit";

export default function DrawEditPage() {
  const { orderId, typeShipping } = useParams<any>();
  const queryClient = useQueryClient();
  const { data: dataOrders, isLoading } = useQuery({
    queryKey: ["draft", orderId ?? ""],
    queryFn: getOrderById(orderId ?? ""),
  });
  const { mutateAsync, isLoading: isLoading1 } = useMutation({
    mutationKey: ["draft", orderId],
    mutationFn: putOrder(orderId),
    onSuccess: async (value: Partial<fullFormProp>) => {
      queryClient.invalidateQueries({ queryKey: ["draft", orderId] });
    },
  });

  const checkLoading = useMemo(() => {
    return isLoading || isLoading1;
  }, [isLoading, isLoading1]);

  const handleDrawSave = async (
    data: any,
    attribute: string,
    values:
      | Partial<Due_diligenceProps>
      | Partial<ShipperProps>
      | Partial<ConsigneeProps>
      | Partial<ShipalProps>
      | Partial<Export_declarationProps>
      | Partial<Package_informationProps[]>
  ) => {
    try {
      const config = await getConfig();
      if(!config.data && typeShipping === SHIPPING_TYPE.INTERNATIONAL) {
        alert("Tenemos problemas al procesar el TRM de hoy");
      }
      const newValues = {
        ...data,
        trm_used: config.data.currencyUSD,
        [attribute]: values,
      };
     
      await mutateAsync(newValues);
      return queryClient.setQueryData(["draft", orderId], newValues);
    } catch (error) {
      console.error(error);
    }
  };

  const formTypeSelect = useMemo(() => {
    if (typeShipping === SHIPPING_TYPE.NATIONAL) {
      return createElement(DraftFormNational, {
        currentData: dataOrders,
        isLoading: checkLoading,
        onSave: handleDrawSave,
        type: typeShipping,
      }, [dataOrders, checkLoading]);
    }

    return createElement(DraftFormInternational, {
      currentData: dataOrders,
      isLoading: checkLoading,
      onSave: handleDrawSave,
      type: typeShipping,
    });
  }, [dataOrders, checkLoading]);

  return (
    <Dashboard>
      <HorizontalBar
        currentPaths={
          typeShipping === SHIPPING_TYPE.NATIONAL
            ? EDIT_PATH_NATIONAL(orderId)
            : EDIT_PATH_INTERNATIONAL(orderId)
        }
        currentData={dataOrders}
        saving={!checkLoading}
      />
      {formTypeSelect}
    </Dashboard>
  );
}
