import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import type {
  ShipperProps,
  ConsigneeProps,
  fullFormProp,
} from "components/molecules/order-forms/types";
import { labelsSelectByCountries } from "components/molecules/order-components/addressInfoCard/dictionary";
import { getCountries, getCities } from "services/serviceV2/orders";
import { labelsSelectByCities } from "components/molecules/order-components/addressInfoCard/dictionary";
import { CountriesStatesSelector } from "components/molecules/countries-states-selector";
import { saveSetValueBySelectMultiValue } from "modules/draft/utils/general";
import { styles } from "components/molecules/order-components/styles";
import { InputSelect } from "commons/input-select";
import {
  Typography,
  Box,
  Divider,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  IconButton,
  Button,
} from "@mui/material";
import { InputText } from "commons/input-text";
import { ADDRESS_TYPE } from "enums/address";
import { SHIPPING_TYPE } from "enums/shipping";
import { CPF_TYPE } from "enums/cpf";
import DeleteIcon from "@mui/icons-material/Delete";
import { REQUIRED_COUNTRIES } from "../order-forms/const";

interface Props {
  addressType: ADDRESS_TYPE;
  methods: any;
  isForQuote?: boolean;
  id?: string;
  formData?: fullFormProp;
  isforAddressBook?: boolean;
  typeShipping?: SHIPPING_TYPE;
}

type MyFieldErrors = FieldErrors<ShipperProps | ConsigneeProps> & {
  [key: string]: FieldValues | undefined;
};

export const AddressBookInfoForm = ({
  addressType,
  methods,
  isForQuote,
  id = "",
  formData,
  isforAddressBook,
  typeShipping,
}: Props) => {
  const { setValue, getValues, control, watch:watchF } = useFormContext<
    ShipperProps | ConsigneeProps
  >();
  const {
    formState: { errors },
    watch,
  } = useFormContext<MyFieldErrors>();
  const [otherAddresses, setOtherAddresses] = useState<string[]>([]);
  const addressKeys: ("address_line2" | "address_line3")[] = [
    "address_line2",
    "address_line3",
  ];
  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });
  const { data: cities } = useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
  });
  const canShowPostalcode = () => {
    let can = false;
    // if(formData?.is_international_shippment){
    //   can = true;
    // } else if(!formData?.is_international_shippment){
    //   if(addressType === ADDRESS_TYPE.SHIPPER || addressType === ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE){
    //     can = true;
    //   }
    //   if(isforAddressBook){
    //     can = true;
    //     if(addressType === ADDRESS_TYPE.SHIPPER){
    //       can = false;
    //     }
    //   }
    // }
    if (getValues().country_name !== "Colombia") {
      can = true;
    }
    return can;
  };
  const onCityChangeSelect = (_target: any, index: any) => {
    if (cities) {
      if (cities[index.key.slice(2)] && cities[index.key.slice(2)].cityName) {
        setValue(
          "state",
          cities[index.key.slice(2)].cityName.match(/\((.*)\)/)?.pop()
        );
      }
      setValue("dane_code", cities[index.key.slice(2)].daneCode);
      // setValue("location_validation", {
      //   "label": "Error, datos no coinciden",
      //   "type": "ERROR",
      //   "postal_code": {
      //     "label": "",
      //     "color": "BLACK"
      //   },
      //   "city": {
      //     "label": "",
      //     "color": "BLACK"
      //   },
      //   "state": {
      //     "label": "",
      //     "color": "BLACK"
      //   }
      // });

      saveSetValueBySelectMultiValue(
        setValue,
        index.key.slice(2),
        labelsSelectByCities,
        cities!
      );
    }
  };

  const onChangeSelectByCountry = (
    _target: any,
    index: any,
    isDefault: boolean
  ) => {
    saveSetValueBySelectMultiValue(
      setValue,
      isDefault ? index.key : index.key.slice(2),
      labelsSelectByCountries,
      countries!
    );
    if (index.props.value === "Puerto Rico") {
      setValue("state", "PR");
    }
  };

  const countryName = watchF("country_name");
  const taxesIsRequired = REQUIRED_COUNTRIES.includes(countryName);

  const validateTaxId = () => {
    return errors.taxes_id && taxesIsRequired;
  };


  useEffect(() => {
    if (
      getValues().country_name &&
      getValues().country_name.length > 0 &&
      countries
    ) {
      saveSetValueBySelectMultiValue(
        setValue,
        countries
          .findIndex(
            (curCountry: any) =>
              curCountry.countryName === getValues().country_name
          )
          .toString(),
        labelsSelectByCountries,
        countries!
      );
    }
    if (getValues().country_name === "Puerto Rico") {
      setValue("state", "PR");
    }
    if (getValues().postal_code?.length && cities) {
      setValue("postal_code", getValues().postal_code || "");
    }
    if (getValues().dane_code?.length && cities) {
      setValue("dane_code", getValues().dane_code || "");
    }
    if (getValues().city?.length && cities) {
      if (
        getValues()
          .city.match(/\((.*)\)/)
          ?.pop()?.length
      ) {
        setValue(
          "state",
          getValues()
            .city.match(/\((.*)\)/)
            ?.pop()
        );
      }
    }
    if (getValues("country_name") !== "Brasil") {
      setValue("tax_identification_type", "");
      setValue("tax_identification", "");
      setValue("ie_rg", "");
    }
    if (getValues("country_name") === "Brasil") {
      setValue("taxes_id", "");
    }
  }, [
    getValues().country_name,
    cities,
    getValues().dane_code,
    getValues().postal_code,
  ]);

  const handleAddAddress = () => {
    for (const address of addressKeys) {
      if (!otherAddresses.includes(address)) {
        setOtherAddresses((prevAddresses) => [...prevAddresses, address]);
        setValue(address, "");
        break;
      }
    }
  };

  const handleRemoveAddress = (
    addressKey: "address_line2" | "address_line3"
  ) => {
    if (
      addressKey === "address_line2" &&
      otherAddresses.includes("address_line3")
    ) {
      return;
    }
    setValue(addressKey, "");
    setOtherAddresses((prevAddresses) =>
      prevAddresses.filter((address) => address !== addressKey)
    );
  };

  useEffect(() => {
    const obj = getValues();
    addressKeys.forEach((key) => {
      if (obj[key] && obj[key] !== undefined) {
        if (!otherAddresses.includes(key)) {
          setOtherAddresses((prevAddresses) => [...prevAddresses, key]);
        }
      }
    });
  }, [watch("address_line2"), watch("address_line3")]);

  return (
    <>
      {!isForQuote && (
        <Box sx={{ padding: 1 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Datos de contacto
          </Typography>
          <Box sx={styles.modal.fieldsRow}>
            <InputText
              name="company_name"
              size="small"
              label="Empresa"
              variant="outlined"
            />
            <InputText
              name="person_name"
              size="small"
              required
              label="Nombre de contacto"
            />
          </Box>

          <Box sx={styles.modal.fieldsRow}>
            <InputText
              name="email"
              size="small"
              required
              label="Email de contacto"
            />
            <InputText
              name="phone_number"
              size="small"
              required
              label="Teléfono de contacto"
            />
          </Box>
        </Box>
      )}

      <Box sx={{ padding: 1 }}>
        {!isForQuote && (
          <>
            <Divider sx={{ width: "100%" }} />
            <Typography variant="h4" sx={{ mb: 2 }}>
              Detalle de dirección
            </Typography>
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {addressType === ADDRESS_TYPE.CONSIGNEE &&
            (formData?.is_international_shippment ||
              typeShipping === SHIPPING_TYPE.INTERNATIONAL) &&
            !isForQuote && (
              <>
                {getValues("country_name") !== "Brasil" && (
                  <InputText
                    size="small"
                    required={taxesIsRequired}
                    name="taxes_id"
                    label={`Identificación Destinatario${
                      !taxesIsRequired ? " (Opcional)" : ""
                    }`}
                    error={validateTaxId()}
                    helperText={
                      validateTaxId() ? errors?.taxes_id?.message : ""
                    }
                  />
                )}

                {getValues("country_name") === "Brasil" && (
                  <>
                    <Typography variant="h4">
                      Tipo de identificación fiscal
                    </Typography>
                    <FormControl
                      error={!!errors.tax_identification_type}
                      component="fieldset"
                    >
                      <Controller
                        name="tax_identification_type"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup row {...field}>
                            <FormControlLabel
                              value={CPF_TYPE.CPF}
                              control={<Radio />}
                              label={CPF_TYPE.CPF}
                            />
                            <FormControlLabel
                              value={CPF_TYPE.CPNJ}
                              control={<Radio />}
                              label={CPF_TYPE.CPNJ}
                            />
                          </RadioGroup>
                        )}
                      />
                      {errors?.tax_identification_type?.message && (
                        <FormHelperText>
                          {errors?.tax_identification_type?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <InputText
                      name="tax_identification"
                      size="small"
                      required={getValues("country_name") === "Brasil"}
                      label="CNPJ / CPF de identificación fiscal"
                      error={errors?.tax_identification != null}
                      helperText={errors.tax_identification?.message}
                    />
                    <InputText
                      name="ie_rg"
                      size="small"
                      error={errors?.ie_rg != null}
                      helperText={errors.ie_rg?.message}
                      label="IE / RG (opcional)"
                    />
                  </>
                )}
              </>
            )}

          {!isForQuote && (
            <>
              <InputText
                size="small"
                required
                name="address_line"
                label="Dirección"
                error={errors.address_line != null}
                helperText={errors.address_line?.message || ""}
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                {otherAddresses.includes("address_line2") && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <InputText
                      size="small"
                      name="address_line2"
                      label="Dirección 2"
                      error={errors.address_line2 != null}
                      helperText={errors.address_line2?.message || ""}
                    />
                    <IconButton
                      onClick={() => handleRemoveAddress("address_line2")}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}

                {otherAddresses.includes("address_line3") && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <InputText
                      size="small"
                      name="address_line3"
                      label="Dirección 3"
                      error={errors.address_line3 != null}
                      helperText={errors.address_line3?.message || ""}
                    />
                    <IconButton
                      onClick={() => handleRemoveAddress("address_line3")}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddAddress}
                >
                  Añadir dirección
                </Button>
              </Box>
            </>
          )}
          <Box
            sx={{
              ...styles.modal.fieldsRow,
              gap: "5rem",
              marginBottom: 0,
            }}
          >
            {methods.getValues("country_name") === "Colombia" ? (
              <InputSelect
                name={`${id}city`}
                size="small"
                id={`${id}city`}
                label="Ciudad"
                fullWidth
                sx={{ height: "100%" }}
                InputProps={{ style: { height: "100%" } }}
                InputLabelProps={{ shrink: true }}
                onChange={onCityChangeSelect as any}
                error={getValues().location_validation?.city?.color === "RED"}
                helperText={getValues().location_validation?.city?.label || ""}
              >
                {cities?.map((city, index) => (
                  <MenuItem key={index} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
              </InputSelect>
            ) : (
              <InputText
                size="small"
                id={`${id}city`}
                required
                name={`${id}city`}
                label="Ciudad"
                error={getValues().location_validation?.city?.color === "RED"}
                helperText={getValues().location_validation?.city?.label || ""}
              />
            )}
            {canShowPostalcode() && (
              <InputText
                size="small"
                id={`${id}postal_code`}
                required
                name={`${id}postal_code`}
                disabled={methods.getValues("country_name") === "Colombia"}
                label="Código postal"
                error={errors[`${id}postal_code`] != null}
                helperText={errors[`${id}postal_code`]?.message || ""}
              />
            )}
          </Box>
          <Box
            sx={{
              ...styles.modal.fieldsRow,
              gap: "5rem",
              marginBottom: 0,
            }}
          >
            <CountriesStatesSelector
              id={id}
              isInternationalShippment={formData?.is_international_shippment}
              addressType={addressType}
              onChangeSelectByCountry={onChangeSelectByCountry}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
